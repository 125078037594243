import React, { useCallback, useEffect, useState } from "react";
import BackgroundSlider from "react-background-slider";

import { Link } from "react-router-dom";
import Button from "../components/Button";

function Main() {
  const data = require("../constants/artists.json");
  const [randomImages, setRandomImages] = useState(null);

  // useEffect(() => {
  //   document
  //     .querySelector('meta[property="og:image"]')
  //     .setAttribute("content", `/socialmedia.jpg`);
  // });

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const getRandomImage = useCallback(() => {
    const allImages = data.artists
      ?.map((artist) => {
        return artist.photos;
      })
      .filter((item) => item.length > 0);
    const mergedImages = [].concat.apply([], allImages);

    setRandomImages(
      shuffle(mergedImages)
        .map((image) => require(`../images/${image}`))
        .slice(0, 8)
    );
  }, [data]);

  useEffect(() => {
    getRandomImage();
  }, [getRandomImage]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("root").scrollTo({ top: 0, behavior: "smooth" });
    }, 150);
  }, []);

  const renderArtists = (stage) => {
    if (!(data.artists?.length > 0)) return null;

    return data.artists.map((artist) => {
      return (
        artist.stage === stage && (
          <Link key={artist.slug} to={`/${artist.slug}`}>
            {artist.members?.map((member, index) => {
              return (
                <span key={member.name}>
                  {index > 0 && <span> + </span>}
                  <span className="artist-name">{member.name}</span>{" "}
                  <span className="artist-country">{member.country}</span>
                </span>
              );
            })}
          </Link>
        )
      );
    });
  };

  if (!data) return null;

  return (
    <>
      <section className="content-block">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <p className="coordinates-primary">SAT 27 AUGUST 2022</p>
            <p className="coordinates-secondary">CHINASTRAAT, GHENT, BELGIUM</p>
          </div>
          {data.tickets.url && <Button href={data.tickets.url} />}
        </div>
      </section>

      {randomImages?.length > 0 && (
        <BackgroundSlider images={randomImages} duration={10} transition={1} />
      )}

      <section className="content-block" id="main-content">
        <p className="line-up">
          <span className="stage">CATHODE</span>
          <br />
          {renderArtists("cathode")}
        </p>
        <p className="line-up">
          <span className="stage">ANODE</span>
          <br />
          {renderArtists("anode")}
        </p>
        <p className="line-up">
          <span className="stage">VISUALS</span>
          <br />
          {renderArtists("visuals")}
        </p>
      </section>
    </>
  );
}

export default Main;
