import React, { useCallback, useEffect, useState } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";

import BackgroundSlider from "react-background-slider";
import ArtistCard from "../components/ArtistCard";

const artists = require("../constants/artists.json")["artists"];

function Artist(props) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [doRedirect, setDoRedirect] = useState(false);

  // useEffect(() => {
  //   if (!artists[currentIndex]) return;
  //   document
  //     .querySelector('meta[property="og:image"]')
  //     .setAttribute(
  //       "content",
  //       require(`../images/${artists[currentIndex].photos[0]}`)
  //     );
  // }, [currentIndex]);

  useEffect(() => {
    if (!artists) return;

    var index = artists.findIndex((artist) => {
      return artist.slug === slug;
    });
    if (index !== -1) {
      setCurrentIndex(index);
    } else {
      // redirect if artist is not found
      setDoRedirect(true);
    }
  }, [slug]);

  const advance = useCallback(
    (direction) => {
      switch (direction) {
        case "prev":
          if (currentIndex - 1 >= 0) {
            //setCurrentIndex(currentIndex - 1);
            navigate(`/${artists[currentIndex - 1].slug}`);
          } else {
            //setCurrentIndex(artists.length - 1);
            navigate(`/${artists[artists.length - 1].slug}`);
          }
          break;
        case "next":
          if (currentIndex + 1 <= artists.length - 1) {
            //setCurrentIndex(currentIndex + 1);
            navigate(`/${artists[currentIndex + 1].slug}`);
          } else {
            //setCurrentIndex(0);
            navigate(`/${artists[0].slug}`);
          }
          break;
        default:
          break;
      }
    },
    [currentIndex, navigate]
  );

  const checkKey = useCallback(
    (e) => {
      e = e || window.event;
      if (e.keyCode === 38) {
        // up arrow
      } else if (e.keyCode === 40) {
        // down arrow
      } else if (e.keyCode === 37) {
        advance("prev");
      } else if (e.keyCode === 39) {
        advance("next");
      }
    },
    [advance]
  );

  useEffect(() => {
    document.addEventListener("keydown", checkKey);

    return () => {
      document.removeEventListener("keydown", checkKey);
    };
  }, [checkKey]);

  const renderBackgroundImage = () => {
    if (!(artists[currentIndex].photos?.length > 0)) {
      return null;
    }

    const images = artists[currentIndex].photos.map((foto) => {
      return require(`../images/${foto}`);
    });

    if (images.length === 1) {
      return (
        <div
          className="fadeIn"
          style={{
            background: `url(${images[0]}) no-repeat center center fixed`,
            backgroundSize: "cover",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -2,
          }}
        />
      );
    } else {
      return (
        <BackgroundSlider
          images={[...images, ...images, ...images, ...images]} // todo: do something about these multiples
          duration={10}
          transition={1}
        />
      );
    }
  };

  if (doRedirect) {
    return <Navigate replace to="/" />;
  }

  if (!artists || !artists[currentIndex] || isNaN(currentIndex)) {
    return null;
  }

  return (
    <div>
      {renderBackgroundImage()}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <nav className="navigation-button" id="previous">
          <button
            onClick={(e) => {
              e.preventDefault();
              advance("prev");
            }}
          >
            <span className="arrow"></span>
          </button>
        </nav>
        <div className="artist-name-block">
          {artists[currentIndex].members?.map((member, index) => {
            return (
              <div key={`member_${index}`}>
                {index > 0 && <span> + </span>}
                {member.name}{" "}
                <span className="artist-country">{member.country}</span>
              </div>
            );
          })}
        </div>
        <nav className="navigation-button" id="next">
          <button
            onClick={(e) => {
              e.preventDefault();
              advance("next");
            }}
          >
            <span className="arrow"></span>
          </button>
        </nav>
      </div>
      <ArtistCard data={artists[currentIndex]} />
    </div>
  );
}

export default Artist;
