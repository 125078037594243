import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { isMobile } from "react-device-detect";

import reportWebVitals from "./reportWebVitals";

import Main from "./routes/Main";
import Artist from "./routes/Artist";
import Page from "./routes/Page";
import Tickets from "./routes/Tickets";

import "./index.css";

const AnimatedRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add("isMobile");
    }
  }, []);

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={10}>
        <Routes location={location}>
          <Route path={":slug"} element={<Artist />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const routes = (
  <Router>
    <Routes>
      <Route path="/" element={<Page />}>
        <Route exact path={"/tickets"} element={<Tickets />} />
        <Route index element={<Main />} />
        <Route path="*" element={<AnimatedRoutes />} />
      </Route>
    </Routes>
  </Router>
);

//ReactDOM.render(routes, document.getElementById("root"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(routes);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
