import React from "react";
import { ReactComponent as IconJoranalogue } from "../images/joranalogue.svg";
import { ReactComponent as IconChinastraat } from "../images/chinastraat.svg";

function Footer(props) {
  return (
    <footer className="content-block center">
      <a href="https://chinastraat.be/" target="_blank" rel="noreferrer">
        <IconChinastraat
          height={50}
          className={"footer-logo"}
          alt="Chinastraat"
        />
      </a>
      <a href="https://joranalogue.com/" target="_blank" rel="noreferrer">
        <IconJoranalogue
          height={50}
          className={"footer-logo"}
          alt="Joranalogue Audio Design"
        />
      </a>
      <p className="footer-text">
        &copy; 2022 PATCHWORK—LIVE MODULAR ELECTRONICS
      </p>
    </footer>
  );
}

export default Footer;
