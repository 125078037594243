import React, { useEffect } from "react";

const data = require("../constants/artists.json")["tickets"];

const Tickets = () => {
  useEffect(() => {
    if (!data.url) return;
    window.location.replace(data.url);
  }, []);

  return (
    <div
      style={{ textAlign: "center", marginTop: "1vh" }}
      className="logo-tagline"
    >
      TICKETS
    </div>
  );
};

export default Tickets;
