import React from "react";

import { Link } from "react-router-dom";

function Header(props) {
  return (
    <>
      <header>
        <Link to="/">
          <div className="logo-main">PATCHWORK</div>
          <div className="logo-tagline">LIVE MODULAR ELECTRONICS</div>
        </Link>
      </header>
      <hr />
    </>
  );
}

export default Header;
