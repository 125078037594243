import React from "react";

import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

function Page(props) {
  return (
    <>
      <div className="content-background" />
      <div className="content-outer">
        <Header />
        <Outlet />
        {/* {props.children} */}
        <hr />
        <Footer />
      </div>
    </>
  );
}

export default Page;
