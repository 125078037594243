import React from "react";
import YouTube from "react-youtube";

const ArtistCard = ({ data }) => {
  if (!data) return null;

  return (
    <section className="content-block" id="main-content">
      <div
        className="ArtistContent"
        style={{ width: "100%" }}
        dangerouslySetInnerHTML={{
          __html: data.content,
        }}
      />
      {data.youtube && (
        <div className="video-outer">
          <YouTube
            videoId={data.youtube}
            id={"youtube"}
            opts={{
              height: "560",
              width: "315",
              playerVars: {
                modestbranding: 1,
                controls: 1,
                rel: 0,
                frameBorder: 0,
                allowfullscreen: true,
                disablepictureinpicture: true,
              },
            }}
          />
        </div>
      )}
    </section>
  );
};

export default ArtistCard;
