import React from "react";

import "./Button.css";

const Button = ({ title = "TICKETS", href = "http://test.test" }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        console.log("buy");
      }}
      className={"Button"}
    >
      <span className="arrow"></span>
      {title}
    </a>
  );
};

export default Button;
